import Head from '../components/head'
import Layout from '../components/layout'
import React from 'react'

const NotFound = () => {
  return (
    <Layout>
      <Head title="404" />
      <h1>Insert 404 Page Here</h1>
    </Layout>
  )
}

export default NotFound